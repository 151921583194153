<template>
  <div>
    <div class="is-flex is-justify-content-space-between">
      <p class="has-text-weight-bold is-size-4 prepayment-title mb-5">
        {{ $t("af:prepayments.title") }}
      </p>
    </div>
    <div class="af-card--user">
      <PrepaymentForm />
    </div>
  </div>
</template>

<script>
export default {
  name: "Prepayments",
  title: "af:prepayments.title",
  components: {
    PrepaymentForm: () =>
      import("@/components/prepaymentrequest/PrepaymentForm.vue"),
  },
};
</script>

<style scoped lang="scss">
.mbh {
  .prepayment-title {
    font-size: 32px !important;
    padding-left: 32px;
  }
}
</style>
